import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import Table from '@components/aria/UI/Table/Table';

export default function Stamps() {
  const { t } = useTranslation();
  const [filters, updateFilters] = useFilters();
  const [tableMetaData, setTableMetaData] = useState(1);
  const [stamps, setStamps] = useState([]);
  const [loading, setLoading] = useState(false);
  const getStamps = () => {
    setLoading(true);
    api
      .jsonAPI()
      .get('stamps', { params: filters })
      .then((res) => {
        setLoading(false);
        setStamps(res.data);
        setTableMetaData(res.data.meta?.pagy);
      })
      .catch((err) => {
        console.error('Error on getStamps', { error: err });
        toast.error(t('Error'));
      });
  };

  const printStamp = (img) => {
    return img?.url ? (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <img src={img.url} width={200} alt="" />
      </div>
    ) : null;
  };

  const COLUMNS = [
    { name: t('title'), key: 'title' },
    {
      name: t('Stamp'),
      key: 'attachment',
      method: printStamp,
    },
  ];

  const userActions = [
    {
      link: '{id}',
      label: 'Edit',
      icon: 'edit',
    },
    {
      label: 'Delete',
      icon: 'trash',
      modal: true,
      modalConfig: {
        header: t('DeleteItem', { item: 'Stamp' }),
        text: t('DeleteModalConfirmation'),
        onClick: (id) => deleteTemplate(id),
        yesButton: t('DeleteItemYes', { item: 'stamp' }),
      },
    },
  ];

  useEffect(() => {
    getStamps();
  }, []);

  useEffect(() => {
    getStamps(filters);
  }, [filters]);

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getStamps(filters),
    },
    { label: 'Create', type: 'primary', icon: 'add', link: 'new' },
  ];

  const deleteTemplate = (id) => {
    api
      .jsonAPI()
      .delete(`/stamps/${id}`)
      .then(() => {
        toast.success(t('ItemDeleted', { item: 'Template' }));
        getStamps();
      })
      .catch((err) => {
        console.error('Error deleting the template', { err });
        toast.error(t('ItemNotDeleted', { item: 'template' }));
      });
  };

  return (
    <div style={{ padding: '0 15px' }}>
      <Table
        columns={COLUMNS}
        items={stamps}
        totalItems={tableMetaData?.count ?? 0}
        buttons={tableButtons}
        actions={userActions}
        loading={loading}
        noItemsKey="No Templates"
        filters={filters}
        pages={tableMetaData?.last ?? 1}
        updateFilters={updateFilters}
      />
    </div>
  );
}
