import { useState } from 'react';
import clsx from 'clsx';
import { Icon } from 'semantic-ui-react';
import { Select, Tooltip, InputNumber } from 'antd';

import s from '@components/DocumentEventsForm/styles.module.scss';

const AdditionalFeesItem = ({
  id,
  description,
  amount,
  removable,
  onChange,
  disabled,
  onRemove,
  options,
}) => {
  const [state, setState] = useState({
    description: description?.value || '',
    amount: amount?.value || '',
  });

  useState(() => {
    setState({ description: description.value, amount: amount.value });
  }, [description, amount, id]);

  const handleBlur = () => {
    if (
      state.description !== description.value ||
      state.amount !== amount.value
    ) {
      onChange({ id, description: state.description, amount: state.amount });
    }
  };

  return (
    <div key={id} className={clsx(s.fieldLine, s.shipmentField)}>
      <div className={s.shipmentInputWithSelect}>
        <Select
          value={state.description || ''}
          showSearch
          optionFilterProp="children"
          options={options}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: '100%' }}
          onChange={(selected) => setState({ ...state, description: selected })}
          onBlur={handleBlur}
          name="originCity"
          disabled={!description.editable || disabled}
          placeholder="Fee description"
        />
        <InputNumber
          precision={2}
          decimalSeparator="."
          controls={false}
          addonAfter="$"
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder="0.00"
          className={s.additionalAmount}
          value={state.amount}
          onChange={(value) => {
            setState({ ...state, amount: value?.toFixed(2) || '' });
          }}
          onBlur={handleBlur}
          name="amount"
          disabled={!amount.editable || disabled}
        />

        <div type="button" className={s.additionalRemoveIconWrapper}>
          {removable && (
            <Tooltip title="Remove">
              <button
                className={s.additionalRemoveIconButton}
                type="button"
                onClick={onRemove}
              >
                <Icon name="minus" />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalFeesItem;
