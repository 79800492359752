import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'semantic-ui-react';
import { Dropdown, Tooltip } from 'antd';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';

import ApplicationLoader from '@components/aria/UI/ApplicationLoader';
import WorkItemCards from '@components/WorkItemCards';
import AriaTable from '@components/aria/UI/Table/Table';
import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import { loadFilters, updateSavedTags } from '@actions';
import {
  USER_ROLES,
  WORK_ITEM_DISPLAY_STATUSES,
  WORK_ITEM_STATUSES,
} from '@constants';
import SavedTags from '@components/WorkItemsList/SavedTags';
import actionCableConstants from '@components/aria/Misc/constants';
import ActionCableContainer from '@components/aria/Misc/ActionCableContainer';
import NewModal from '@components/Modal/NewModal';
import usePermissions from '@hooks/usePermissions';
import MergeList from '@components/MergeList';

import s from './styles.module.scss';
import { getWorkItemColumns, createTableData } from './TableSettings';

const mergeableStatuses = [
  WORK_ITEM_STATUSES.needsAttention,
  WORK_ITEM_STATUSES.paused,
  WORK_ITEM_STATUSES.replied,
  WORK_ITEM_STATUSES.finalReview,
];

const WorkItemsList = () => {
  const { canViewHyperScienceStatus, canViewInProcessWorkItems } =
    usePermissions();
  const { t } = useTranslation();
  const { canDeleteWorkItems } = usePermissions();
  const [workItems, setWorkItems] = useState([]);
  const [firstLoading, setFirstLoading] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableMetaData, setTableMetaData] = useState(1);
  const storedFilters = useSelector((state) => state.filtersReducer.filters);
  const indexTags = useSelector(
    (state) => state.currentUserReducer.user.indexTags,
  );
  const currentUser = useSelector((state) => state.currentUserReducer.user);
  const userId = useSelector((state) => state.currentUserReducer.user.id);
  const [filters, updateFilters] = useFilters({ ...storedFilters });
  const dispatch = useDispatch();
  const [stats, setStats] = useState({});
  const [filtersOptions, setFiltersOptions] = useState({
    tags: [],
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [modal, setModal] = useState({
    type: '',
    message: '',
  });

  const workItemsColumns = getWorkItemColumns({
    canViewHyperScienceStatus,
  });

  const onApprove = () => {
    setModal({
      type: '',
      message: '',
    });
    const selectedWorkItemsIds = selectedAll
      ? workItems.map((item) => item.id)
      : selectedItems;
    toast.promise(
      api
        .jsonAPI()
        .post(`/output_batches/approve`, {
          data: { work_item_ids: selectedWorkItemsIds },
        })
        .then((res) => {
          if (selectedAll) {
            setSelectedAll(false);
          } else {
            setSelectedItems([]);
          }
          return res?.data;
        }),
      {
        loading: 'Approving...',
        success: (approvedItems) => {
          return `${approvedItems?.length} work items was approved`;
        },
        error: (error) => {
          return (
            error?.response?.data?.messages?.join('\n ') ||
            'Something went wrong'
          );
        },
      },
      {
        error: {
          duration: 6000,
        },
      },
    );
  };

  const onRestore = () => {
    setModal({
      type: '',
      message: '',
    });
    const selectedWorkItemsIds = selectedAll
      ? workItems.map((item) => item.id)
      : selectedItems;
    toast.promise(
      api
        .restAPI()
        .patch(`work_items/mass_restore`, {
          id: selectedWorkItemsIds,
        })
        .then((res) => {
          getWorkItems();
          if (selectedAll) {
            setSelectedAll(false);
          } else {
            setSelectedItems([]);
          }
          return res?.data?.count;
        }),
      {
        loading: 'Restoring...',
        success: (restoredCount) => {
          return `${restoredCount} work items was restored`;
        },
        error: (error) => {
          return (
            error?.response?.data?.messages?.join(', ') ||
            'Something went wrong'
          );
        },
      },
      {
        error: {
          duration: 6000,
        },
      },
    );
  };

  const onRemove = () => {
    setModal({
      type: '',
      message: '',
    });

    const selectedWorkItemsIds = selectedAll
      ? workItems.map((item) => item.id)
      : selectedItems;
    toast.promise(
      api
        .restAPI()
        .patch(`work_items/mass_destroy`, {
          id: selectedWorkItemsIds,
        })
        .then((res) => {
          setWorkItems(
            workItems.filter((item) => {
              return !selectedWorkItemsIds.includes(item.id);
            }),
          );
          if (selectedAll) {
            setSelectedAll(false);
          } else {
            setSelectedItems([]);
          }
          return res?.data?.count;
        }),
      {
        loading: 'Removing...',
        success: (removeCount) => {
          return `${removeCount} work items was removed`;
        },
        error: (error) => {
          return (
            error?.response?.data?.messages?.join(', ') ||
            'Something went wrong'
          );
        },
      },
      {
        error: {
          duration: 6000,
        },
      },
    );
  };

  const hasModalButtons = {
    approve: true,
    remove: true,
    restore: true,
    merge: null,
  };

  const modalActions = {
    approve: onApprove,
    remove: onRemove,
    restore: onRestore,
    cancel: () => {
      setModal({
        type: '',
        message: '',
      });
    },
  };
  const modalTitles = {
    approve: t('Are you sure?'),
    remove: t('Are you sure?'),
    restore: t('Are you sure?'),
    merge: t('Choose the Principal Work Item'),
  };

  const onSelectItem = ({ id }) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(
        selectedItems.filter((itemId) => {
          return itemId !== id;
        }),
      );
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const onActionCableRefresh = (refresh) => {
    const { type } = refresh;
    const { actionCableActions } = actionCableConstants;
    switch (type) {
      case actionCableActions.status_change: {
        const { id, status } = refresh;
        setWorkItems((prev) =>
          prev.map((item) => {
            if (Number(item.id) === Number(id)) {
              return {
                ...item,
                status: {
                  caption: WORK_ITEM_DISPLAY_STATUSES[status],
                  name: status,
                },
              };
            }
            return item;
          }),
        );
        getStatuses();
        break;
      }
      case actionCableActions.lock: {
        const { id, lock } = refresh;
        setWorkItems((prev) =>
          prev.map((item) => {
            if (Number(item.id) === Number(id)) {
              return {
                ...item,
                locked: lock,
              };
            }
            return item;
          }),
        );
        break;
      }
      case actionCableActions.unlock: {
        const { id } = refresh;
        setWorkItems((prev) =>
          prev.map((item) => {
            if (Number(item.id) === Number(id)) {
              return {
                ...item,
                locked: null,
              };
            }
            return item;
          }),
        );
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(`Unknown action cable action: ${type}`);
      }
    }
  };

  const updateReduxFilters = (auxFilters) => {
    dispatch(loadFilters(123, auxFilters));
    // dispatch(loadFilters(app.uuid, auxFilters));
  };
  const getStatuses = debounce(
    () => {
      api
        .restAPI()
        .get('/work_items/status')
        .then(({ data }) => {
          const _data = data?.data;
          setStats(_data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    10000,
    { leading: true },
  );

  const getWorkItems = () => {
    setLoading(true);
    getStatuses();

    api
      .jsonAPI()
      .get('/tags')
      .then(({ data }) => {
        setFiltersOptions((prev) => ({
          ...prev,
          tags: data,
        }));
      })
      .catch((err) => {
        console.error(err);
      });

    api
      .jsonAPI()
      .get(`/work_items`, { params: filters })
      .then(({ data }) => {
        setWorkItems(data);
        setFirstLoading(false);
        if (data.meta) {
          setTableMetaData(data.meta.pagy);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getWorkItems();
  }, []);

  useEffect(() => {
    setSelectedItems([]);
    if (!firstLoading) {
      getWorkItems();
      updateReduxFilters(filters);
    }
  }, [filters]);

  if (!workItems.length && firstLoading) {
    return <ApplicationLoader text={t('Loading work items...')} />;
  }

  const tableData = createTableData(workItems);

  const onRowClick = (item) => {
    const canView =
      (item.status.name === WORK_ITEM_STATUSES.inProcess &&
        canViewInProcessWorkItems) ||
      item.status.name !== WORK_ITEM_STATUSES.inProcess;

    if (canView) {
      navigate(`workitems/${item.workItemId}`, {
        state: { activityFromTable: item, filters },
      });
    }
    // navigate(`${location.pathname}/activities/${item.id}`, {
    //   state: { app, activityFromTable: item, filters },
    // });
  };

  const onMergeSubmit = (mainId) => {
    modalActions.cancel();
    toast
      .promise(
        api.jsonAPI().patch(`/work_items/merge`, {
          work_items: selectedItems,
          main: mainId,
        }),
        {
          loading: t('Merging work items...'),
          success: t('Work items merged'),
          error: (error) => {
            return (
              error?.response?.data?.messages?.join(', ') ||
              'Something went wrong'
            );
          },
        },
      )
      .then(() => {
        setSelectedItems([]);
        getWorkItems();
      });
  };

  const modalContent = {
    merge: (
      <MergeList
        onSubmit={onMergeSubmit}
        onCancel={modalActions.cancel}
        items={workItems.filter((currentWI) => {
          return selectedItems
            .map((id) => Number(id))
            .includes(Number(currentWI.id));
        })}
      />
    ),
  };

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getWorkItems(),
    },
    // { label: 'Create', type: 'primary', icon: 'add', link: 'new' },
  ];

  const onSavedFiltersChange = (value, type) => {
    switch (type) {
      case 'tags': {
        const tags = value.map((tag) => ({
          id: uuidv4(),
          attributes: { name: tag },
        }));
        api
          .jsonAPI()
          .patch(`/users/${userId}/index_tags`, { tags: value })
          .then(() => {
            dispatch(updateSavedTags(tags));
            getWorkItems();
          });
        break;
      }
      default: {
        break;
      }
    }
  };

  const preSavedFiltersOptions = {
    tags: filtersOptions?.tags?.map((tag) => ({
      label: tag.name,
      value: tag.name,
    })),
  };

  const preSavedFiltersValues = {
    indexTags,
  };

  const canMergeWorkItems =
    (workItems
      .filter((item) => {
        return selectedItems.map((id) => Number(id)).includes(Number(item.id));
      })
      .map((item) => {
        return item?.status?.name;
      })
      .every((status) => mergeableStatuses?.includes(status)) &&
      selectedItems?.length <= 5 &&
      selectedItems?.length > 1) ||
    false;

  const items = [
    {
      key: 'Approve',
      invisibleFor: ['Deleted'],
      label: 'Approve',
      disabled: !currentUser?.roles?.includes(USER_ROLES.approver),
      onClick: () =>
        setModal({
          message: t('Are you sure you want to approve this work items?'),
          type: 'approve',
        }),
    },
    {
      key: 'Delete',
      invisibleFor: ['Deleted'],
      label: 'Delete',
      disabled: !canDeleteWorkItems,
      onClick: () =>
        setModal({
          message: t('Are you sure you want to delete this work items?'),
          type: 'remove',
        }),
    },
    {
      key: 'Restore',
      label: 'Restore',
      disabled: !canDeleteWorkItems,
      onClick: () =>
        setModal({
          message: t('Are you sure you want to restore this work items?'),
          type: 'restore',
        }),
    },
    {
      key: 'Merge',
      label: 'Merge',
      title:
        'Only 5 work items can be merged at a time, with statuses Needs Attention, Pause, Replied, Final review',
      className: 'merge',
      disabled: !canMergeWorkItems,
      onClick: () =>
        setModal({
          type: 'merge',
        }),
    },
  ].filter((actionItem) => {
    return !actionItem?.invisibleFor?.includes(filters['filter[status][]']);
  });

  const actionsDisabled = selectedItems.length === 0 && !selectedAll;

  return (
    <Fragment>
      <ActionCableContainer
        active
        token={currentUser.jwt}
        channel="WorkItemsChannel"
        onReceived={onActionCableRefresh}
      />
      <NewModal
        width="auto"
        title={modalTitles[modal.type]}
        visible={modal.type}
        onSubmit={() => modalActions[modal.type]()}
        onCancel={modalActions.cancel}
        onCrossClick={modalActions.cancel}
        footer={hasModalButtons[modal.type]}
      >
        {modal.message}
        {modalContent[modal.type]}
      </NewModal>
      <div className={s.contentWrapper}>
        <WorkItemCards
          userRoles={currentUser?.roles}
          stats={stats}
          filters={filters}
          updateFilters={updateFilters}
        />
        <AriaTable
          selectedAll={selectedAll}
          selectedItems={selectedItems}
          onItemSelect={onSelectItem}
          onSelectAll={() => setSelectedAll(!selectedAll)}
          className="workItemTable"
          firstLoading={firstLoading}
          onRowClick={onRowClick}
          columns={workItemsColumns}
          items={tableData}
          totalItems={tableMetaData?.count} // pagination
          loading={loading}
          filters={filters}
          updateFilters={updateFilters}
          noItemsKey="No work items"
          pages={tableMetaData?.last}
          sortColumns
          disableScrollToView
          disableFilterMenu={false}
          buttons={tableButtons}
          toolsComponent={
            <>
              <SavedTags
                preSavedFiltersValues={preSavedFiltersValues}
                onSavedFiltersChange={onSavedFiltersChange}
                preSavedFiltersOptions={preSavedFiltersOptions}
              />
              <Tooltip
                title={actionsDisabled ? 'Select at least one item' : ''}
              >
                <div style={{ cursor: 'pointer' }}>
                  <Dropdown
                    menu={{ items }}
                    arrow
                    disabled={actionsDisabled}
                    trigger="click"
                  >
                    <Button
                      style={{
                        border: '1px solid rgb(34, 125, 152)',
                        boxShadow: '0 0 0 1px #2185d0 inset',
                        backgroundColor: 'white',
                        color: '#2185d0',
                      }}
                    >
                      Actions
                    </Button>
                  </Dropdown>
                </div>
              </Tooltip>
            </>
          }
        />
      </div>
    </Fragment>
  );
};

export default WorkItemsList;
