import { Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Icon, Label } from 'semantic-ui-react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { toast } from 'react-hot-toast';

import {
  statusColors,
  USER_ROLES,
  approverStatuses,
  auditorStatuses,
  WORK_ITEM_STATUSES,
} from '@constants';
import {
  setWorkItem as setWorkItemAction,
  resetWorkItem,
  setAlerts as setAlertsAction,
  setWorkItem,
  updateDocuments,
} from '@actions';
import { useGetRoles } from '@hooks/useGetRoles';
import api from '@services/axios';
import ApplicationLoader from '@components/aria/UI/ApplicationLoader';
import DropdownMenu from '@components/DropdownMenu';
import EmailClient from '@components/EmailClient/EmailClient';
import NewModal from '@components/Modal/NewModal';
import actionCableConstants from '@components/aria/Misc/constants';
import ActionCableContainer from '@components/aria/Misc/ActionCableContainer';
import TagsEditor from '@components/TagsEditor';
import usePermissions from '@hooks/usePermissions';
import { dayjsWithTimezone } from '@services/helpers';

import HSWidget from '../aria/Application/Activity/Hyperscience/HSWidget';

import s from './styles.module.scss';
import PDFViewerWrapper from './PDFViewerWrapper';

const showApproveButton = (roles, status) => {
  const userIsAprover = roles.includes(USER_ROLES.approver);
  const userIsAuditor = roles.includes(USER_ROLES.auditor);

  if (userIsAprover && approverStatuses.includes(status)) return true;

  return !!(userIsAuditor && auditorStatuses.includes(status));
};

const Header = ({
  workItem,
  clientName,
  clientEmail,
  onEmailsClick,
  onSaveEmail,
  isSaveActive,
  lockedBy,
  readOnly,
  onUnlock,
  onTagsChange,
  onRemove,
  availableTags,
  discarded,
  onWorkItemChange,
  hasCloseIcon,
}) => {
  const navigate = useNavigate();
  const date = dayjsWithTimezone(workItem.receivedAt).format(
    'MM/DD/YYYY, HH:mm',
  );
  const emailDate = dayjsWithTimezone(workItem.emailedAt).format(
    'MM/DD/YYYY, HH:mm',
  );
  const batchId = workItem?.batch?.data?.id;
  const batchSubmissionId =
    workItem?.batch?.data?.attributes?.submissionId || '';
  const workItemCurrentStatus = workItem?.status?.name;
  const hyperscienceId = workItem?.externalId;
  const outboundBatchId = workItem?.outputBatchId;
  const color = statusColors.get(workItemCurrentStatus);
  const userRoles = useGetRoles();
  const showApprove = showApproveButton(userRoles, workItem?.status?.name);
  const [modal, setModal] = useState({
    type: '',
    message: '',
  });
  const { canDeleteWorkItems } = usePermissions();

  const currentWICount =
    workItem?.batch?.data?.attributes?.workItems?.findIndex(
      (currentWorkItem) =>
        parseInt(currentWorkItem, 10) === parseInt(workItem.id, 10),
    ) + 1;

  const onUnlockClick = () => {
    setModal({
      type: 'unlock',
      message: 'Are you sure you want to unlock this work item?',
    });
  };

  const onRemoveClick = () => {
    if (!discarded?.length) {
      setModal({
        type: 'remove',
        message: 'Are you sure you want to delete this work item?',
      });
    } else {
      setModal({
        type: 'restore',
        message: 'Are you sure you want to restore this work item?',
      });
    }
  };

  const modalActions = {
    unlock: () => {
      onUnlock();
      setModal({
        type: '',
        message: '',
      });
    },
    remove: () => {
      onRemove();
      setModal({
        type: '',
        message: '',
      });
    },
    restore: () => {
      onRemove();
      setModal({
        type: '',
        message: '',
      });
    },
    cancel: () => {
      setModal({
        type: '',
        message: '',
      });
    },
  };

  return (
    <>
      {modal?.type && (
        <NewModal
          title="Confirm"
          onCancel={() => modalActions.cancel()}
          onCrossClick={() => modalActions.cancel()}
          onSubmit={() => modalActions[modal?.type]()}
          submitButtonText="Yes"
          cancelButtonText="Cancel"
          visible
        >
          {modal?.message}
        </NewModal>
      )}
      <div className={s.approval}>
        <div className={s.headerButtons}>
          <DropdownMenu disabled={lockedBy && readOnly} />
          <Tooltip
            title={
              !workItem?.outputFields?.client?.id &&
              'Please choose a client in order to group this work item'
            }
          >
            <Button
              type="button"
              className={s.groupingButton}
              onClick={() => navigate(`/grouping/${workItem.id}`)}
              disabled={!workItem?.outputFields?.client?.id || readOnly}
            >
              Grouping
            </Button>
          </Tooltip>
          {canDeleteWorkItems && (
            <Button
              type="button"
              className={s.groupingButton}
              onClick={onRemoveClick}
            >
              {discarded ? 'Restore' : 'Delete'} work item
            </Button>
          )}
          <Button
            type="button"
            className={s.emailsButton}
            onClick={onEmailsClick}
          >
            Email client &nbsp;
            {workItem?.status?.name === 'replied' && (
              <Label circular color="red" className={s.emailBudge} />
            )}
            <MailOutlined />
          </Button>
          {lockedBy?.user?.name && (
            <div className={s.lockedBlock}>
              <div className={s.userName}>
                Locked by: {lockedBy?.user?.name}
              </div>
              <div className={s.unlockButton} onClick={onUnlockClick}>
                Unlock
              </div>
            </div>
          )}
          <div
            className={clsx(s.download, {
              [s.downloadButtonMargin]: !showApprove,
            })}
          />
        </div>
        <div className={s.headerBatchInfo}>
          <div className={s.batchId}>
            Batch #: {batchId} ({batchSubmissionId})
          </div>
          <div className={s.workItemId}>
            Work Item: {workItem.id} ({hyperscienceId})
          </div>
          <div className={s.workItemId}>
            Work Item: {currentWICount}/
            {workItem?.batch?.data?.attributes?.workItems?.length}
          </div>
          <div className={s.workItemId}>
            Outbound Batch ID: {outboundBatchId}
          </div>
        </div>
        <div className={s.navigationBar}>
          {onWorkItemChange && (
            <Tooltip title="Previous Work Item">
              <ArrowLeftOutlined
                style={{
                  fontSize: 15,
                  marginRight: 10,
                }}
                onClick={() => onWorkItemChange('prev')}
              />
            </Tooltip>
          )}
          {onWorkItemChange && (
            <Tooltip title="Next Work Item">
              <ArrowRightOutlined
                style={{
                  fontSize: 15,
                  marginRight: 10,
                }}
                onClick={() => onWorkItemChange('next')}
              />
            </Tooltip>
          )}
          {hasCloseIcon && (
            <Link style={{ textDecoration: 'none', color: 'unset' }} to="/">
              <Icon name="close" />
            </Link>
          )}
        </div>
      </div>

      <div className={s.invoice}>
        <div className="email">
          <div className={s.workItemName}>{clientName}</div>
          <div className={s.workItemEmail}>
            {clientEmail}
            <div
              className={clsx(
                s.saveEmailButton,
                isSaveActive && s.saveEmailButtonActive,
              )}
              onClick={() => {
                onSaveEmail(clientEmail);
              }}
            >
              Save email address
            </div>
          </div>
        </div>
        <div className="date">
          Email date:
          <div className={s.invoiceData}>{emailDate}</div>
        </div>
        <div className="date">
          Received at:
          <div className={s.invoiceData}>{date}</div>
          <div className={s.invoiceStatusText}>
            {workItem?.batch?.data?.attributes?.subject_line}
          </div>
        </div>
        <div>
          <Tag style={{ width: '100%', textAlign: 'center' }} color={color}>
            {workItem?.status?.caption}
          </Tag>
        </div>
        <TagsEditor
          value={workItem?.tags?.map((tag) => tag?.attributes?.name) || []}
          onChange={onTagsChange}
          availableTags={availableTags}
          readOnly={readOnly}
        />
      </div>
    </>
  );
};

const WorkItemDetailed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const workItem = useSelector((state) => state.workItemReducer.workItem);
  const currentUser = useSelector((state) => state.currentUserReducer.user);
  const storedFilters = useSelector((state) => state.filtersReducer.filters);
  const { t } = useTranslation();
  const { id } = useParams();

  const [isLoading, setIsloading] = useState(true);
  const [widget, setWidget] = useState(false);
  const [showEmails, setShowEmails] = useState(false);
  const [savedEmails, setSavedEmails] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  const isWorkItem = workItem?.id && Object?.keys(workItem)?.length;

  const onActionCableRefresh = (refresh) => {
    const { type } = refresh;
    const { actionCableActions } = actionCableConstants;
    switch (type) {
      case actionCableActions.lock: {
        const { id: lockedID, lock } = refresh;
        if (
          Number(id) === Number(lockedID) &&
          currentUser.id !== lock.user.id
        ) {
          toast.error(`This work item was reassign by the ${lock.user.email}`);
          dispatch(setWorkItem({ ...workItem, locked: lock }));
        }
        break;
      }
      case actionCableActions.unlock: {
        const { id: lockedID } = refresh;
        if (Number(id) === Number(lockedID)) {
          onUnlockWI();
        }
        break;
      }
      case actionCableActions.documents_updated: {
        const { id: updatedWID } = refresh;
        if (Number(id) === Number(updatedWID)) {
          api
            .jsonAPI()
            .get(`/work_items/${updatedWID}`)
            .then((res) => {
              setIsloading(false);
              // Update only documents to prevent refreshing all the data
              dispatch(updateDocuments(res.data.documents));
            })
            .catch((err) => {
              navigate('/');
              console.error('err2', err);
            });
        }
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(`Unknown action cable action: ${type}`);
      }
    }
  };

  useEffect(() => {
    return () => dispatch(resetWorkItem());
  }, [dispatch]);

  const onEmailSave = (email) => {
    const data = {
      email: {
        clientId: workItem.outputFields.client.id,
        email,
      },
    };
    api
      .jsonAPI()
      .post('contact_emails', data)
      .then((res) => setSavedEmails([...savedEmails, res.data]));
  };

  const onUnlockWI = () => {
    api
      .restAPI()
      .patch(`work_items/${id}/force_lock`)
      .then((res) => {
        dispatch(setWorkItem({ ...workItem, locked: res.data }));
        toast.success('Work item was successfully reassigned!');
      })
      .catch((err) => console.error(err));
  };

  const onRemoveWI = () => {
    if (workItem.discardedAt) {
      toast.promise(
        api
          .jsonAPI()
          .patch(`work_items/${workItem?.id}/restore_destroy`)
          .then((res) => {
            dispatch(setWorkItem(res.data));
          }),
        {
          loading: 'Restoring work item...',
          success: 'Work item was successfully restored!',
          error: 'Work item was not restored!',
        },
      );
    } else {
      toast.promise(
        api
          .jsonAPI()
          .delete(`work_items/${workItem?.id}`)
          .then(() => {
            navigate('/');
          })
          .catch((err) => console.error(err)),
        {
          loading: 'Removing work item...',
          success: 'Work item was successfully removed!',
          error: 'Work item was not removed!',
        },
      );
    }
  };

  const onTagsChange = async (tags) => {
    await api
      .jsonAPI()
      .patch(`work_items/${workItem?.id}/tags`, {
        data: {
          tags,
        },
      })
      .then((res) => {
        dispatch(setWorkItem({ ...workItem, tags: res.data.tags }));
      });
  };

  const onWorkItemChange = (type) => {
    if (type === 'prev' || type === 'next') {
      api
        .restAPI()
        .get(`/work_items/${type}?id=${workItem?.id}`, {
          params: storedFilters,
        })
        .then((res) => {
          if (res.data.id) {
            navigate(`/workitems/${res.data.id}`);
          } else {
            toast.error(`Can't find ${type} work item`);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          toast.error(`Can't find ${type} work item`);
        });
    }
  };
  useEffect(() => {
    setIsloading(true);
    api
      .jsonAPI()
      .get(`tags`)
      .then((res) => {
        setAvailableTags(res?.data?.map((item) => item?.name) || []);
      });
    api
      .jsonAPI()
      .get(`/work_items/${id}`)
      .then((res) => {
        setIsloading(false);
        dispatch(setWorkItemAction(res.data));
      })
      .catch((err) => {
        navigate('/');
        console.error('err2', err);
      });
  }, [id]);

  useEffect(() => {
    if (workItem?.outputFields?.client?.id) {
      api
        .restAPI()
        .get(`contact_emails?client_id=${workItem.outputFields.client.id}`)
        .then((response) => {
          setSavedEmails(
            response.data.data.map((email) => ({
              key: email.id,
              email: email.attributes.email,
            })),
          );
        });
    } else {
      setSavedEmails([]);
    }
  }, [workItem?.outputFields?.client?.id]);

  useEffect(() => {
    api
      .restAPI()
      .get(`/work_items/alerts`)
      .then((res) => {
        dispatch(setAlertsAction(res.data?.data));
      })
      .catch((err) => {
        console.error('err', err);
      });
  }, [dispatch]);

  if (!isWorkItem || isLoading) {
    return <ApplicationLoader text={t('Loading work item...')} />;
  }

  const clientItem = workItem?.outputFields;
  const clientName = clientItem?.client?.name;
  const clientEmail = workItem?.batch?.data?.attributes?.email;
  const editableForCurrentUser = workItem?.locked?.user?.id === currentUser?.id;
  const discarded = workItem?.discardedAt;
  const readOnly =
    workItem?.status?.name === WORK_ITEM_STATUSES.rejected ||
    workItem?.status?.name === WORK_ITEM_STATUSES.approved ||
    workItem?.status?.name === WORK_ITEM_STATUSES.error ||
    // workItem?.status?.name === WORK_ITEM_STATUSES.factored ||
    workItem?.status?.name === WORK_ITEM_STATUSES.completed ||
    workItem?.status?.name === WORK_ITEM_STATUSES.inProcess ||
    !editableForCurrentUser ||
    discarded?.length > 0;

  return (
    <>
      <ActionCableContainer
        active
        token={currentUser.jwt}
        channel="WorkItemsChannel"
        onReceived={onActionCableRefresh}
      />
      {showEmails && (
        <EmailClient readOnly={readOnly} onClose={() => setShowEmails(false)} />
      )}
      <div className={s.contentWrapper}>
        <Header
          hasCloseIcon
          onWorkItemChange={onWorkItemChange}
          workItem={workItem}
          clientName={clientName}
          clientEmail={clientEmail}
          widget={widget}
          setWidget={setWidget}
          onEmailsClick={() => setShowEmails(true)}
          isSaveActive={
            Boolean(workItem.outputFields?.client?.id) &&
            !savedEmails.find(
              (item) =>
                item?.email?.toLowerCase() === clientEmail?.toLowerCase(),
            ) &&
            !isLoading &&
            clientEmail?.length > 0 &&
            workItem.outputFields?.client?.email.toLowerCase() !==
              clientEmail.toLowerCase()
          }
          onSaveEmail={onEmailSave}
          lockedBy={editableForCurrentUser ? null : workItem?.locked}
          readOnly={readOnly}
          onUnlock={onUnlockWI}
          availableTags={availableTags}
          onTagsChange={onTagsChange}
          onRemove={onRemoveWI}
          discarded={discarded}
        />
        {widget ? (
          <HSWidget
            service_url="https://transflohs.invk.cloud/"
            submission_id={1}
            closePoup={setWidget}
          />
        ) : (
          <>
            <PDFViewerWrapper workItem={workItem} readOnly={readOnly} />
          </>
        )}
      </div>
    </>
  );
};

export default WorkItemDetailed;
